<template>
  <section class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input placeholder="设备编号" class="each" v-model="searchInfo.param.deviceNumber"></el-input>
      <el-cascader placeholder="所属商户 / 所属小区"
        class="each"
        clearable
        v-model="unitId"
        :options="managerUnitOptions"
        @change="chooseMerchant"
        :props="props"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select placeholder="安装小区" class='each' v-model="searchInfo.param.unitId" clearable  v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select placeholder="在线状态" class='each' v-model="searchInfo.param.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select placeholder="运营状态" class='each' v-model="searchInfo.param.operateState" clearable>
        <el-option
          v-for="item in operateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select placeholder="厂商类型" class='each' v-model="searchInfo.param.brandType" clearable>
        <el-option
          v-for="item in brandTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null)">新增</el-button>
    </div>
    <div class="tableContainer">
      <!-- 表格数据 -->
      <el-table :data="tableList" height="100%">
        <el-table-column align="center" label="设备编号" prop="id" width="150">
          <template slot-scope="scope">
            <p style="display:flex;align-items:center;color:#40a9ff;justify-content: center;">
              {{ scope.row.id }}
              <img style="cursor: pointer; margin-left: 3px; height:15px;width:15px" src="@/static/img/qrCode.png" alt="点击查看设备二维码" @click="openQrCode(scope.row)">
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="厂商类型" prop="brandTypeName">
          <template slot-scope="scope">
            {{ scope.row.brandTypeName ? scope.row.brandTypeName : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="端口数量" prop="deviceTypeName">
          <template slot-scope="scope">
            {{ scope.row.portQuantityName ? scope.row.portQuantityName : '--' }}
          </template>
        </el-table-column>
        <el-table-column align='center' label="端口状态" prop="PortState" width='170px'>
          <template slot-scope="scope">
            <div class="portStatus">
              <el-tooltip class="item"  v-for="(item,index) in scope.row.chargingPilePorts" :key="index" effect="dark" :content="item.errorCode == 0 || !item.errorCode ? item.portStatusName : item.errorMsg" placement="top">
                <el-button :class="item.errorCode == 0 || !item.errorCode ? (item.portStatus == 0 ? 'free' : (item.portStatus == 1 ? 'charging' :(item.portStatus == 2 ? 'startCharging': 'take'))) : 'fault'">
                  {{item.portNum}}
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="在线状态" prop="statusName">
          <template slot-scope="scope">
            {{ scope.row.statusName ? scope.row.statusName : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="运营状态" prop="operateStateName">
          <template slot-scope="scope">
            {{ scope.row.operateStateName ? scope.row.operateStateName : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="计费模板" prop="consumptionStatus">
          <template slot-scope="scope">
            <el-button size="mini" @click='updateTemplate(scope.row)' v-if="userInfo.roleLevel <= 5">更新</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="二维码更新状态" prop="isLatest"  v-if="userInfo.roleLevel == 0">
          <template slot-scope="scope">
            {{ scope.row.qrStatus == 0 ? '未下发' : ( scope.row.qrStatus == 1 ? '下发中' : ( scope.row.qrStatus == 2 ? '成功' : '失败' ))}}
            <el-button size="mini"  @click='updateQrCode(scope.row)' v-if="userInfo.roleLevel <= 5">更新</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            {{ scope.row.version ? (Number(scope.row.version)/100).toFixed(2) : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="安装小区" prop="unitName">
          <template slot-scope="scope">
            {{ scope.row.unitName ? scope.row.unitName : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="具体安装位置" prop="address">
          <template slot-scope="scope">
            {{ scope.row.address ? scope.row.address : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="remark">
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '--' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTimeStr">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleStop(scope.row)"> {{scope.row.operateState == 2 ? '退出维护' : '维护' }}</el-button>
            <el-button size="mini" @click="stopPort(scope.row)" v-if="userInfo.roleLevel < 10">强制停止端口</el-button>
            <el-popover placement="top" trigger="click" width="auto">
                <div class="moreBtn">
                  <span class="spanBtn" @click="openDrawer(scope.row)"  v-if="userInfo.roleLevel <= 5">编辑</span>
                  <span class="spanBtn" @click="handleDel(scope.row)"  v-if="userInfo.roleLevel <= 5">删除</span>
                  <span class="spanBtn" @click="restart(scope.row)">重启</span>
                  <span class="spanBtn" @click="changeNetWork(scope.row)" v-if="userInfo.roleLevel == 0">转网</span>
                  <!-- <span class="spanBtn" @click="handleElectric(scope.row)">耗电统计</span> -->
                </div>
                <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增、编辑 -->
      <el-drawer
        :title="row ? '编辑' : '新增'"
        :visible.sync="addDrawer"
        size="50%">
        <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 耗电统计 -->
      <el-drawer
        title="耗电统计"
        :visible.sync="electricDawer"
        size="80%">
        <electricStatistics v-if="electricDawer" @close="handleClose"  ref='newForm' :row="row"></electricStatistics>
      </el-drawer>
      <!-- 设置消费模板 -->
      <el-drawer
        title="设置消费模板"
        :visible.sync="templateDrawer"
        size="50%"
      >
        <templateVue v-if="templateDrawer" @close='handleClose' ref='newForm' :row="row"></templateVue>
      </el-drawer>
      <!-- 分页 -->
      <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
      <!-- 停止端口弹框 -->
      <el-dialog title="强制停止端口" :visible.sync="dialogFormVisible">
        <el-form ref="stopForm" :model="stopForm" label-width="150px" :rules="stopPortRules">
          <el-form-item label="设备编号" >
           <p>{{ stopForm.id }}</p>
          </el-form-item>
          <el-form-item label="端口号" prop="port">
            <el-radio-group v-model="stopForm.port" size="mini">
              <el-radio-button v-for="(item,index) in stopForm.chargingPilePorts" :key="index" :label="item.portNum"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmStopPort('stopForm')">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 转网 -->
      <el-dialog title="设备转网" :visible.sync="changeNetFormVisible">
        <el-form ref="netWorkForm" :model="netWorkForm" label-width="150px"  :rules="netWorkRule">
          <el-form-item label="设备编号">
           <p>{{ netWorkForm.id }}</p>
          </el-form-item>
          <el-form-item label="IP地址" prop="ip">
            <el-input placeholder="请输入IP地址" v-model="netWorkForm.ip"></el-input>
          </el-form-item>
          <el-form-item label="IP对应端口" prop="port">
            <el-input placeholder="请输入IP对应端口" v-model="netWorkForm.port"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changeNetFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmChangeNet">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 更新模板 -->
      <el-dialog title="更新模板" :visible.sync="updateTemplateVisible" width="70%">
        <div class="template">
          <el-card :class="updateTemplateForm.lastConsume == 1 ? 'chooedCard box-card' : 'box-card'">
            <div slot="header" class="clearfix">
              <span>小区计费模板</span>
              <el-button class="updateBtn" type="text" @click="confirmUpdateTemplate(1)" v-if="updateTemplateForm.unitTemplate && updateTemplateForm.lastConsume == 2">使用</el-button>
              <el-button class="updateBtn" type="text" v-if="updateTemplateForm.lastConsume == 1">使用中</el-button>
            </div>
            <el-form class="templateDetail" label-width="140px" v-if="updateTemplateForm.unitTemplate">
              <!-- 充电时长 -->
              <el-form-item label="免费进入时长(分钟)" v-if="updateTemplateForm.unitTemplate.billingModel == 1">
                <p>{{ updateTemplateForm.unitTemplate.freeDuration ? updateTemplateForm.unitTemplate.freeDuration : 0 }}</p>
              </el-form-item>
              <el-form-item label="最大功率(W)" v-if="updateTemplateForm.unitTemplate.billingModel == 1">
                <p>{{ updateTemplateForm.unitTemplate.overloadPower ? updateTemplateForm.unitTemplate.overloadPower : 0 }}</p>
              </el-form-item>
              <el-form-item label="收费规则" v-if="updateTemplateForm.unitTemplate.billingModel == 1">
                <div class="eachPhase">
                  <p v-for="(item,index) in updateTemplateForm.unitTemplate.rules" :key="index">
                    {{ item.duration + '时 / ' + item.ele + '元' }}
                  </p>
                </div>
              </el-form-item>
              <!-- 用电量 -->
              <el-form-item label="收费档位" v-if="updateTemplateForm.unitTemplate.billingModel == 2">
                <el-tag size="mini" v-for="item in updateTemplateForm.unitTemplate.prepaidAmounts" :key="item">{{ item }}元</el-tag>
              </el-form-item>
              <el-form-item label="电损率（%）" v-if="updateTemplateForm.unitTemplate.billingModel == 2">
                <el-tag size="mini">{{ updateTemplateForm.unitTemplate.eleLoss ? updateTemplateForm.unitTemplate.eleLoss : 0 }}</el-tag>
              </el-form-item>
              <el-form-item label="收费规则" v-if="updateTemplateForm.unitTemplate.billingModel == 2">
                <div class="eachPhase">
                    <div v-for="(item,index) in updateTemplateForm.unitTemplate.rules" :key="index" class="eachRule">
                      <p>{{ item.startTime + '时—' + item.endTime + '时&nbsp;&nbsp;' }}</p>
                      <p>
                        <span>电费：{{ item.ele + '元/度' }}</span>
                        <span>服务费：{{ item.fee + "元/度" }}</span>
                      </p>
                    </div>
                </div>
              </el-form-item>
            </el-form>
            <div v-else>暂无数据</div>
          </el-card>
          <el-card :class=" updateTemplateForm.lastConsume == 2 ? 'chooedCard box-card' : 'box-card'">
            <div slot="header" class="clearfix">
              <span>设备自定义计费模板</span>
              <el-button class="updateBtn" type="text" @click="confirmUpdateTemplate(2)" v-if="updateTemplateForm.chargePileTemplate && updateTemplateForm.lastConsume == 1">使用</el-button>
              <el-button class="updateBtn" type="text" v-if="updateTemplateForm.lastConsume == 2">使用中</el-button>
            </div>
            <el-form class="templateDetail" label-width="140px"  v-if="updateTemplateForm.chargePileTemplate">
              <div @click="toTemplate" class="editTemplate">编辑</div>
              <el-form-item label="免费进入时长(分钟)" v-if="updateTemplateForm.chargePileTemplate.billingModel == 1">
                <p>{{ updateTemplateForm.chargePileTemplate.freeDuration ? updateTemplateForm.chargePileTemplate.freeDuration : 0 }}</p>
              </el-form-item>
              <el-form-item label="最大功率(W)" v-if="updateTemplateForm.chargePileTemplate.billingModel == 1">
                <p>{{ updateTemplateForm.chargePileTemplate.overloadPower ? updateTemplateForm.chargePileTemplate.overloadPower : 0 }}</p>
              </el-form-item>
              <el-form-item label="收费规则" v-if="updateTemplateForm.chargePileTemplate.billingModel == 1">
                <div class="eachPhase">
                    <p v-for="(item,index) in updateTemplateForm.chargePileTemplate.rules" :key="index">
                      {{ item.duration + '时 / ' + item.ele + '元' }} 
                    </p>
                </div>
              </el-form-item>
              <!-- 用电量 -->
              <el-form-item label="收费档位" v-if="updateTemplateForm.chargePileTemplate.billingModel == 2">
                <el-tag size="mini" v-for="item in updateTemplateForm.chargePileTemplate.prepaidAmounts" :key="item">{{ item }}元</el-tag>
              </el-form-item>
              <el-form-item label="电损率（%）" v-if="updateTemplateForm.chargePileTemplate.billingModel == 2">
                <el-tag size="mini">{{ updateTemplateForm.chargePileTemplate.eleLoss ? updateTemplateForm.chargePileTemplate.eleLoss : 0 }}</el-tag>
              </el-form-item>
              <el-form-item label="收费规则" v-if="updateTemplateForm.chargePileTemplate.billingModel == 2">
                <div class="eachPhase">
                    <div v-for="(item,index) in updateTemplateForm.chargePileTemplate.rules" :key="index" class="eachRule">
                      <p>{{ item.startTime + '时—' + item.endTime + '时&nbsp;&nbsp;' }}</p>
                      <p>
                        <span>电费：{{ item.ele + '元/度' }}</span>
                        <span>服务费：{{ item.fee + "元/度" }}</span>
                      </p>
                    </div>
                </div>
              </el-form-item>
            </el-form>
            <div v-else @click="toTemplate" class="toTemplate">去设置</div>
          </el-card>
        </div>
      </el-dialog>
      <!-- 设备二维码 -->
      <el-dialog :title="'设备编号：'+qrCodeInfo.id" :visible.sync="qrCodeVisible" width="330px">
        <vue-qr :text="qrCode" :size="200"></vue-qr>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import add from './add.vue';
import templateVue from './template.vue';
import electricStatistics from './electricStatistics.vue';
import { getNeighbourhoodList,getMerchantList,getChargePileList,deleteChargePile,stopCharging,maintainChargePile,restartChargePile,carConsumption,updateQrCode,changNetWork,getTemplateList } from '@/api/public.js';
import vueQr from 'vue-qr';
import qrCodeObj from '@/constants/miniProgramQrCodeUrl.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      dialogFormVisible:false, // 是否显示强制停止端口弹框
      stopForm:{}, // 停止端口表单
      stopPortRules:{ // 停止端口表单验证
        port:[
          {required:true,message:'请选择需要停止的端口',trigger:'blur'}
        ],
      },
      changeNetFormVisible:false, // 是否显示转网弹框
      netWorkForm:{}, // 转网表单
      updateTemplateVisible:false, // 是否显示更新模板弹框
      updateTemplateForm:{ // 更新模板表单
        unitTemplate:null,
        nowTemplate:null,
        chargePileTemplate:null,
      },
      netWorkRule:{ // 设备转网表单验证
        ip:[
          { required: true, message: 'IP不得为空', trigger: 'blur'}
        ],
        port:[
          { required: true, message: 'IP对应端口不得为空', trigger: 'blur'}
        ],
      },
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      searchInfo:{ // 搜索条件
        pageNo:1,
        pageSize:10,
        total:1,
        param:{
          deviceNumber:null, // 设备编号
          status:null, // 在线状态
          merchantId:null, // 商户id
          unitId:null, // 小区id
          operateState:null, // 运营状态
          brandType:null, // 厂商类型
          type:1, // 设备类型
        }
      },
      tableList:[], // 表格数据列表
      unitOptions:[], // 小区选项
      statusOptions:[ // 在线状态选项
        {value:1,label:'在线'},
        {value:2,label:'离线'},
      ],
      operateOptions:[ // 运营状态选项
        {value:1,label:'运营中'},
        {value:2,label:'停止运营'},
        {value:3,label:'故障'},
      ],
      managerUnitOptions:[ // 所属商户、所属小区选项
        {
          value:1,
          label:'乐电',
          children:[
            { value:1,label:'光阳国际'},
            { value:2,label:'近江八园'},
          ]
        },
      ],
      typeOptions:[ // 充电桩类型选项
        {value:1,label:'电瓶车桩'},
        {value:2,label:'新能源汽车桩'},
      ],
      deviceTypeOptions:[ // 端口数量
        {value:1,label:'单枪'},
        {value:2,label:'双枪'},
        {value:16,label:'16口'},
        {value:20,label:'20口'},
      ],
      brandTypeOptions:[ // 厂商类型选项
        {value:1,label:'友电'},
        {value:3,label:'乐电'},
      ],
      unitId:[],  // 所属商户、小区联动选择框绑定数据
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          console.log(node,987)
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                    nodes.push({
                      id: element.id,
                      name: element.name,
                      leaf: level >= 1,
                      children:null,
                    })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }
          }, 1000);
        }
      },
      addDrawer:false, // 控制新增、编辑弹框是否显示
      electricDawer:false, // 控制耗电统计弹框是否显示
      templateDrawer:false, // 控制消费模板弹框是否显示
      row:null, // 表格中当前操作的一栏数据
      qrCodeVisible:false,
      qrCode:'',
      qrCodeInfo:{
        id:null,
      }
    }
  },

  components: {
    Pagination,
    add,
    electricStatistics,
    templateVue,
    vueQr
  },

  computed: {},

  mounted() {
    // 用户权限小于5时才会有商户选项
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
    this.init();
  },

  methods: {
    // 查看二维码
    openQrCode(row){
      this.qrCodeInfo = {...row};
      let hexString = Number(row.id).toString(16).toUpperCase(); // 10进制转为16进制
      while (hexString.length < 8) { // 保证转为16进制后设备编号为8位数
        hexString = '0' + hexString;
      }
      let sexTeen = hexString.match(/.{2}/g).reverse().join(''); // 两两一组分割成数组后反转数组再转换为字符串，此时得到设备上的二维码
      this.qrCode = `${qrCodeObj.batteryChargePile}${sexTeen}`;
      // this.qrCode = `${qrCodeObj.testBatteryChargePile}${sexTeen}`; // 测试服
      this.qrCodeVisible = true;
    },
    // 搜索事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          deviceNumber:null, // 设备编号
          status:null, // 在线状态
          merchantId:null, // 商户id
          unitId:null, // 小区id
          operateState:null, // 运营状态
          brandType:null, // 厂商类型
          type:1, // 设备类型
        }
        this.unitId = [];
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 获取设备列表
    async init(){
      await getChargePileList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element=>{
            element.createTimeStr = timeChange(element.createTime,'seconds');
          })
        }
      })
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 打卡侧边弹框
    openDrawer(row){
      this.row = row;
      this.addDrawer = true;
    },
    // 打开耗电统计弹框
    handleElectric(row){
      this.row = row;
      this.electricDawer = true;
    },
    // 打开设置消费模板弹框
    handleTemplate(row){
      this.row = row;
      this.templateDrawer = true;
    },
    // 前往设置自定义消费模板
    toTemplate(){
      this.templateDrawer = true;
      this.updateTemplateVisible = false;
    },
    // 关闭弹框
    handleClose(){
      this.addDrawer = false;
      this.electricDawer = false;
      this.templateDrawer = false;
      this.row = null;
      this.init();
    },
    // 设备进入、退出维护状态
    handleStop(row){
      this.$confirm('确认进行该操作吗？').then(async ()=>{
        let data = {
          param:{
            chargingPileId:row.id, // 充电桩id
            operateState: row.operateState == 2 ? 1 : 2, // 运营状态
          }
        }
        await maintainChargePile(data).then(res=>{
          this.$message({
            message:res.success ? '操作成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 打开强制停止端口弹窗
    async stopPort(row){
      this.stopForm = {...row};
      this.dialogFormVisible = true;
    },
    // 确认强制停止
    confirmStopPort(){
      this.$refs["stopForm"].validate(valid => {
        if(valid){
          this.$confirm('强制停止后，正在充电的订单将被强制结束，确认继续吗？').then(async ()=>{
            let data = {
              param:{
                chargingPileId:this.stopForm.id,
                portNum:this.stopForm.port,
              }
            }
            await stopCharging(data).then(res=>{
              if(res.success){
                this.dialogFormVisible = false;
                this.$message({
                  message:'端口已停止',
                  type:'success',
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail',
                })
              }
            })
            this.init();
          })
        }
      })
    },
    // 设备重启
    restart(row){
      this.$confirm('确认重启该设备吗？').then(async ()=>{
        await restartChargePile({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功' : res.msg,
            type:res.success ? 'success' : 'fail',
          })
          this.init();
        })
      })
    },
    // 打开转网弹框
    changeNetWork(row){
      this.changeNetFormVisible = true;
      this.netWorkForm = {...row};
    },
    // 确认设备转网
    confirmChangeNet(){
      this.$confirm('确认进行该操作吗？').then(async ()=>{
        let data = {
          param:{
            chargingPileId: this.netWorkForm.id,
            ip: this.netWorkForm.ip,
            port: this.netWorkForm.port,
          }
        }
        await changNetWork(data).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功' : res.msg,
            type:res.success ? 'success' : 'fail',
          })
          this.init();
          this.changeNetFormVisible = false;
        })
      })
    },
    // 同步最新计费方式
    async updateTemplate(row){
      this.row = {...row};
      this.updateTemplateForm = {...row};
      // 获取小区计费模板
      let data = {
        param:{
          targetId:row.unitId,
          targetType:1,
          type:row.type,
        }
      }
      await getTemplateList(data).then(res=>{
        if(res.success && res.data && res.data.length > 0){
          this.updateTemplateForm.unitTemplate = res.data[0]
        }else{
          this.updateTemplateForm.unitTemplate = null;
        }
      })
      let data1 = {
        param:{
          targetId:row.id,
          targetType:2,
          type:row.type,
        }
      }
      // 获取设备计费模板
      await getTemplateList(data1).then(res=>{
        if(res.success && res.data && res.data.length > 0){
          this.updateTemplateForm.chargePileTemplate = res.data[0];
        }else{
          this.updateTemplateForm.chargePileTemplate = null;
        }
      })
      this.updateTemplateVisible = true;
    },
    // 确认下发计费方式
    confirmUpdateTemplate(type){
      this.$confirm('确认进行该操作吗？').then(async ()=>{
        let data = {
          param:{
            chargingPileId:this.updateTemplateForm.id,
            targetType:type,
          }
        }
        await carConsumption(data).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功' : res.msg,
            type:res.success ? 'success' : 'fail',
          })
          this.row.lastConsume = type;
          this.updateTemplate(this.row);
          this.updateTemplateVisible = false;
          this.init();
          this.row = null;
        })
      })
    },
    // 更新二维码
    updateQrCode(row){
      this.$confirm('确认进行该操作吗？').then(async ()=>{
        await updateQrCode({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功' : res.msg,
            type:res.success ? 'success' : 'fail',
          })
          this.init();
        })
      })
    },
    // 删除
    handleDel(row){
      this.$confirm('确认删除该设备吗？').then(async ()=>{
        await deleteChargePile({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '删除成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
.eachRule{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  p{
    display: flex;
    flex-direction: column;
    span{
      display: inline-block;
      line-height: 20px;
    }
  }
}
.chooedCard {
  box-shadow: 0 2px 12px 0 rgba($color: #88d75f, $alpha: 0.6) !important;
  background: rgba($color: #88d75f, $alpha: 0.1) !important;
}
.toTemplate{
  display: inline-block;
  color: blue;
  cursor: pointer;
}
.editTemplate{
  color: blue;
  cursor: pointer;
  text-align: right;
}
.template{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .box-card{
    width: 45%;
    margin-bottom: 30px;
    .updateBtn{
      float: right;
      padding: 3px 0;
      color: blue;
    }
  }
}
/deep/ .el-radio-button--mini .el-radio-button__inner{
  border-left: 1px solid #dcdfe6;
}
.portStatus{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.moreBtn{
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.spanBtn{
  width: 100%;
  cursor: pointer;
  display: inline-block;
  color: #40a9ff;
  text-align: center;
  padding-bottom: 5px;
}
.eachPort{
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  border: solid 1px #ccc;
  cursor: pointer;
}
.el-tooltip{
  display: inline-block;
  padding: 0 6px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  margin-left: 0 !important;
  width: 30px;
}
.free{ // 空闲
  color: #88d75f;
  border: #88d75f solid 1px  !important;
  background: #f6ffed;
}
.charging{ // 充电中
  color: #3aa1ff;
  border: #3aa1ff solid 1px !important;
  background: #e6f7ff;
}
.startCharging{ // 启动中
  color: #7ad3ee;
  border: #7ad3ee solid 1px !important;
  background: #e6f7ff;
}
.fault{ // 故障
  color: #ff696b;
  border: #ff696b solid 1px !important;
  background: #fff2f0;
}
.take{ // 已插枪
  color: #e2cd57;
  border: #e2cd57 solid 1px  !important;
  background: #ffffe5;
}
</style>
