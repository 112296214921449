<template>
  <div class="container">
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="carRules" label-width="180px" style="width:95%;overflow:auto">
      <el-form-item label="设备编号" prop="name" >
        <p style="font-weight: 600;">{{ row.id }}</p>
      </el-form-item>
      <!-- <el-form-item label="计费模式" prop="billingModel">
        <el-radio-group v-model="form.billingModel">
          <el-radio-button :label="1">充电时长</el-radio-button>
          <el-radio-button :label="2">用电量</el-radio-button>
        </el-radio-group>
      </el-form-item> -->
      <!-- 用电量 -->
      <el-form-item label="收费档位(元)" prop="prepaidAmounts" v-if="form.billingModel == 2">
        <el-select v-model="form.prepaidAmounts" placeholder="请选择" multiple style="width:100%">
          <el-option
            v-for="item in amountGearsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最大功率（W）" prop="overloadPower">
        <el-input palceholder="请输入最大功率（W）" type="number" v-model="form.overloadPower"></el-input>
      </el-form-item>
      <el-form-item label="收费规则" prop="rules" v-if="form.billingModel == 2">
        <div class="eachPhase">
            <p v-for="(item,index) in form.rules" :key="index">
                {{ `${item.startTime}时-${item.endTime}时&nbsp;&nbsp;&nbsp;` }}
                电费：{{ item.ele + '元/度' }}
                服务费：{{ item.fee + '元/度' }}
                <i class="el-icon-delete" @click="deleteTemplate(index)"></i>
            </p>
        </div>
        <el-button  class="button-new-tag" size="small" @click="addTemplate" style="width:100%;border:dashed #c0c4cc 1px;">+ 添加梯度</el-button>
        <p class="explain">时间段请覆盖全天24小时</p>
      </el-form-item>
      <!-- 充电时长 -->
      <el-form-item label="免费进入时长（分钟）" prop="freeDuration" v-if="form.billingModel == 1">
        <el-input palceholder="请输入免费进入时长" type="number" v-model="form.freeDuration"></el-input>
      </el-form-item>
      <el-form-item label="收费规则" prop="rules" class="gradientTemplate" v-if="form.billingModel == 1">
        <div v-for="(item,index) in form.rules" :key="index" class='eachTemplate'>
          <p><el-input type="number" v-model="item.duration"></el-input><span>小时</span></p>
          <p><el-input type="number" v-model="item.ele"></el-input><span>元</span></p>
          <i class="el-icon-delete" @click="deleteTemplate(index)"></i>
        </div>
        <el-button  class="button-new-tag" size="small" @click="addTimeTemplate">+ 添加梯度</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="handleSubmit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="rulesVisible" title="收费规则" :modal="false">
        <p class="remind">时间采用24小时制，必须从0-23小时构成连续的几个时间段梯度，且时间段之间不能存在重合部分</p>
        <el-form ref="timeRateForm" :model="timeRateForm" label-position="right" status-icon label-width="180px" :rules="timeRateRules" style="width:95%;overflow:auto">
          <el-form-item label="开始时间" prop="startTime">
            <el-input palceholder="请输入开始时间" type="number" v-model="timeRateForm.startTime"></el-input>
          </el-form-item>
          <el-form-item label="结束时间" prop="endTime">
            <el-input palceholder="请输入结束时间" type="number" v-model="timeRateForm.endTime"></el-input>
          </el-form-item>
          <el-form-item label="电费（元/度）" prop="ele">
            <el-input palceholder="请输入该时间段电费" type="number" v-model="timeRateForm.ele"></el-input>
          </el-form-item>
          <el-form-item label="服务费（元/度）" prop="fee">
            <el-input palceholder="请输入该时间段服务费" type="number" v-model="timeRateForm.fee"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="rulesVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import{ createTemplate,updateTemplate,getTemplateList } from '@/api/public.js';
import { positiveInteger,maxpower } from '@/api/publicFun.js';
export default {
    props:["row"],
    data() {
        let feeRules = (rule,value,callback)=>{
          if(value.length == 0){
            callback(new Error('请至少填写一条收费规则'));
          }else{
            callback();
          }
        }
        let prepaidAmountsRules = (rule,value,callback)=>{
          if(value.length == 0){
            console.log('fail')
            callback(new Error('请至少选择一个收费档位'));
          }else{
            callback();
          }
        }
        return {
            rulesVisible:false, // 是否显示新增电量模式收费规则的弹框
            form:{ // 表单数据
              billingModel:1, // 计费模式，1充电时长、2用电量
              freeDuration:0, // 免费进入时长
              minConsume:0, // 最低消费
              rules:[], // 规则
              serviceFee:0, // 服务费
              type:null, // 车辆类型 1电瓶车、2新能源车
              unitId:null, // 小区id
              defaultFee:0, // 默认费用，每度电（用电量模式）
              prepaidAmounts:[],
            },
            carRules:{ // 电量模式收费模板表单验证
              rules:[
                { required:true,validator:feeRules, trigger:'change'},
              ],
              serviceFee:[
                {required:true,message:'服务费不得为空',trigger:'blur'}
              ],
              prepaidAmounts:[
                { required:true,validator:prepaidAmountsRules, trigger:'change'},
              ],
              overloadPower:[
                {required:true,message:'最大功率不得为空',trigger:'blur'},
                {required:true,validator:maxpower,trigger:'blur'}
              ],
              freeDuration:[
                {required:true,message:'免费进入时长不得为空',trigger:'blur'}
              ],
            },
            rules: { // 电瓶车费率表单验证
              overloadPower:[
                {required:true,message:'最大功率不得为空',trigger:'blur'},
                {required:true,validator:maxpower,trigger:'blur'}
              ],
              freeDuration:[
                {required:true,message:'免费进入时长不得为空',trigger:'blur'}
              ],
              rules:[
                { required:true,validator:feeRules, trigger:'change'},
              ],
            },
            timeRateRules:{ // 时间段费率表单验证
              startTime:[
                {required:true,message:'开始时间不得为空',trigger:'blur'},
                {required:true,validator:positiveInteger,trigger:'blur'}
              ],
              endTime:[
                {required:true,message:'结束时间不得为空',trigger:'blur'},
                {required:true,validator:positiveInteger,trigger:'blur'}
              ],
              ele:[
                {required:true,message:'电费不得为空',trigger:'blur'}
              ],
              fee:[
                {required:true,message:'服务费不得为空',trigger:'blur'}
              ],
            },
            timeRateForm:{ // 电量模式时间段费率表单
              startTime:0,
              endTime:0,
              fee:0,
              ele:0,
              serviceFee:0,
            },
            amountGearsOptions:[ // 充电金额选项
              {label:0.5,value:0.5,},
              {label:1,value:1,},
              {label:1.5,value:1.5,},
              {label:2,value:2,},
              {label:2.5,value:2.5,},
              {label:3,value:3,},
              {label:3.5,value:3.5,},
              {label:4,value:4,},
              {label:4.5,value:4.5,},
              {label:5,value:5,},
              {label:6,value:6,},
              {label:7,value:7,},
              {label:8,value:8,},
              {label:9,value:9,},
              {label:10,value:10,},
            ],
        }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        this.form.type = this.row.type;
        this.getTemplateInfo();
    },
    methods: {
      // 关闭弹框
      close(){
        this.$emit('close');
      },
      // 查询当前消费模板的详细信息
      async getTemplateInfo(){
        this.form = { // 表单数据
          billingModel:1,
          freeDuration:0,
          minConsume:0, 
          rules:[],
          serviceFee:0,
          type:this.form.type,
          targetId:this.row.id,
          defaultFee:0, 
          prepaidAmounts:[],
        }
        let data = {
          param:{
            targetId:this.form.targetId,
            targetType:2,
            type:this.form.type,
          }
        }
        await getTemplateList(data).then(res=>{
          if(res.success){
            this.form = res.data.length > 0 ? res.data[0] : this.form;
          }else{
            this.form = this.form;
          }
        })
      },
      // 打开电量模式弹框
      addTemplate(){
        this.rulesVisible = true;
      },
      // 确认新增电量模式梯度
      confirm(){
          let each = {
            startTime: this.timeRateForm.startTime,
            endTime: this.timeRateForm.endTime,
            fee: Number(this.timeRateForm.fee),
            ele: Number(this.timeRateForm.ele),
            duration: 0,
          }
          // 判断时间段是否与之前的存在重复部分
          let isRight = true;
          for(let i=0;i<this.form.rules.length;i++){
            if(Number(this.timeRateForm.startTime) > Number(this.form.rules[i].startTime) && Number(this.timeRateForm.startTime) < Number(this.form.rules[i].endTime)){
              isRight = false;
              break;
            }else if(Number(this.timeRateForm.endTime) > Number(this.form.rules[i].startTime) && Number(this.timeRateForm.endTime) < Number(this.form.rules[i].endTime)){
              isRight = false;
              break;
            }
          }
          if(isRight){
            this.$refs["timeRateForm"].validate(valid => {
              if(valid){
                this.form.rules.push(each);
                this.rulesVisible = false;
              }
            })
          }else{
            this.$message({
              message:'梯度时间不构成连续，请修改',
              type:'fail',
            })
          }
      },
      // 删除定价档位
      deleteTemplate(index){
        this.form.rules.splice(index,1)
      },
      // 添加时间梯度模板
      addTimeTemplate(){
        this.form.rules.push({duration:0,fee:0,ele:0,endTime:0,startTime:0})
      },
      // 删除定价档位
      deleteTemplate(index){
        this.form.rules.splice(index,1)
      },
      // 添加
      async add(data){
        await createTemplate(data).then(res=>{
          if(res.success){
            this.$emit('handleClose');
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 编辑
      async edit(data){
        await updateTemplate(data).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success',
            })
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 提交
      handleSubmit(){
        this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.form.billingModel == 1){
                    this.form.rules.forEach(element => {
                      element.duration = Number(element.duration);
                      element.fee = 0;
                      element.ele = Number(element.ele);
                      element.endTime = 0;
                      element.startTime = 0;
                    });
                    this.form.prepaidAmounts = [];
                  }else{
                    this.form.rules.forEach(element => {
                      element.duration = 0;
                      element.ele = Number(element.ele);
                      element.fee = Number(element.fee);
                      element.endTime = element.endTime;
                      element.startTime = element.startTime;
                    });
                  }
                  let data = {
                    param:{
                      id:this.form.id, // 消费模板id
                      billingModel:Number(this.form.billingModel),
                      defaultFee:Number(this.form.defaultFee),
                      freeDuration:Number(this.form.freeDuration),
                      minConsume:Number(this.form.minConsume),
                      rules:this.form.rules,
                      serviceFee:Number(this.form.serviceFee),
                      type:Number(this.form.type),
                      targetId:this.row.id,// 目标id 小区id、设备id
                      targetType:2,
                      prepaidAmounts:this.form.prepaidAmounts,
                      overloadPower:this.form.overloadPower,
                    }
                  }
                  this.edit(data);
                })
            }
        })
      },
    },
};
</script>
<style scoped lang="scss">
.remind{
  text-align: left;
  line-height: 30px;
  color: #808080;
}
.container{
  padding: 20px;
}
// 模板
.gradientTemplate{
   .el-input{
     border-radius: 0 !important;
   }
   .el-button{
     border:dashed #c0c4cc 1px;
     width:100%;
     outline:none;
   }
   p{
     display: flex;
     justify-content: flex-start;
     width: 49%;
     height: 40px;
     display: inline-block;
     box-sizing: border-box;
     span{
       display: inline-block;
       border:solid 1px #dbdee5 ;
       width:20%;
       height: 100%;
       box-sizing: border-box;
     }
   }
 } 
.eachTemplate{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    p{
      width: 45%;
      display: flex;
      .el-input{
        flex: 1;
        outline: none;
      }
      span{
        display: inline-block;
        width: 30%;
        border: solid #dbdee5 1px;
        font-size: 13px;
      }
    }
    
  }
  .el-icon-delete{
      display: inline-block;
      height: 13px;
      width: 13px;
      color: rgb(240, 12, 12);
      font-weight: 900;
      &:hover{
        cursor:pointer;
      }
    }
  /deep/ .el-select{
    width: 100%;
  }
</style>
